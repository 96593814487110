<template>
  <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td>
              <div align="center"><font size="+2">
                                </font>              </div>
<br>
<br>
              <h3>Copyright</h3>
              <font face="Verdana, Arial, Helvetica, sans-serif">The
STAF database is
the property of Columbia University. This data can only be used for
research or academic purposes. Any commercial use of the data
whatsoever or incorporation of the data into a larger database intended
for public distribution must be done with the explicit written consent
of the <a href="mailto:staf@lists.cs.columbia.edu">STAF
administrators</a>. <br>
<br>
              </font>
              <hr>
              <h3>Source Code and Specification Download</h3>
              <ul>
                <li><a href="https://cave.cs.columbia.edu/old/databases/staf/STAF_Release.zip">Code</a> to
                  estimate the STAF model: 151 KB, in Matlab</li>
                <li>Matlab <a href="https://cave.cs.columbia.edu/old/databases/staf/exrio.zip">code and plugins</a> for EXR read/write: 790 KB</li>
                <li><a href="https://cave.cs.columbia.edu/old/databases/staf/README.zip">Dome Specification</a>:
                  1.2MB</li>
              </ul>
              <hr style="width: 100%; height: 2px;">
              <h3>List of Samples</h3>
              <table width="100%" border="1" cellpadding="0" cellspacing="0">
                <tbody align="center">
                  <tr>
                    <td width="128"><b>Thumbnail</b></td>
                    <td width="162"><b>Resolution (width*height*time)</b></td>
                    <td width="147"><b>Description</b></td>
                    <td width="124" valign="middle"><b>Download</b></td>
                    <td width="76"><div align="center"><b>Size (MB)</b></div></td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF09.gif"></td>
                    <td>512*512*10</td>
                    <td>wood_burning</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF09.tar.gz">tvBTF09.tar.gz</a></td>
                    <td>85.1</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF20.gif"></td>
                    <td>360*360*11</td>
                    <td>rock_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF20.tar.gz">tvBTF20.tar.gz</a></td>
                    <td>49.6</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF21.gif"></td>
                    <td>300*300*23</td>
                    <td>wood_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF21.tar.gz">tvBTF21.tar.gz</a></td>
                    <td>65.6</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF22.gif"></td>
                    <td>512*512*33</td>
                    <td>orange_cloth_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF22.tar.gz">tvBTF22.tar.gz</a></td>
                    <td>288.2</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF23.gif"></td>
                    <td>300*300*34</td>
                    <td>light_wood_2_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF23.tar.gz">tvBTF23.tar.gz</a></td>
                    <td>93.3</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF24.gif"></td>
                    <td>300*300*28</td>
                    <td>white_felt_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF24.tar.gz">tvBTF24.tar.gz</a></td>
                    <td>71.4</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF25.gif"></td>
                    <td>300*300*32</td>
                    <td>quilted_paper_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF25.tar.gz">tvBTF25.tar.gz</a></td>
                    <td>102.2</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF26.gif"></td>
                    <td>420*420*29</td>
                    <td>cardboard_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF26.tar.gz">tvBTF26.tar.gz</a></td>
                    <td>177.3</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF27.gif"></td>
                    <td>260*260*32</td>
                    <td>wet_brick_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF27.tar.gz">tvBTF27.tar.gz</a></td>
                    <td>76.7</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF28.gif"></td>
                    <td>180*180*33</td>
                    <td>apple_core_decaying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF28.tar.gz">tvBTF28.tar.gz</a></td>
                    <td>32.6</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF29.gif"></td>
                    <td>420*420*14</td>
                    <td>wood_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF29.tar.gz">tvBTF29.tar.gz</a></td>
                    <td>74.1</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF30.gif"></td>
                    <td>420*420*30</td>
                    <td>green_cloth_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF30.tar.gz">tvBTF30.tar.gz</a></td>
                    <td>185.3</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF31.gif"></td>
                    <td>220*220*33</td>
                    <td>banana_decaying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF31.tar.gz">tvBTF31.tar.gz</a></td>
                    <td>47.2</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF32.gif"></td>
                    <td>300*300*22</td>
                    <td>steel_rusting</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF32.tar.gz">tvBTF32.tar.gz</a></td>
                    <td>62.7</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF33.gif"></td>
                    <td>300*300*30</td>
                    <td><p>leaf_change_under<br>
                      _humid_heat</p>
                    </td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF33.tar.gz">tvBTF33.tar.gz</a></td>
                    <td>85.2</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF34.gif"></td>
                    <td>420*420*31</td>
                    <td>wet_pattern_cloth</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF34.tar.gz">tvBTF34.tar.gz</a></td>
                    <td>187.9</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF35.gif"></td>
                    <td>156*156*35</td>
                    <td>apple_slice_decaying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF35.tar.gz">tvBTF35.tar.gz</a></td>
                    <td>24.9</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF37.gif"></td>
                    <td>340*340*27</td>
                    <td>granite_drying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF37.tar.gz">tvBTF37.tar.gz</a></td>
                    <td>103.3</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF38.gif"></td>
                    <td>180*180*11</td>
                    <td>tree_bark</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF38.tar.gz">tvBTF38.tar.gz</a></td>
                    <td>13.1</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF39.gif"></td>
                    <td>200*200*36</td>
                    <td>potato_decaying</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF39.tar.gz">tvBTF39.tar.gz</a></td>
                    <td>38.7</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF40.gif"></td>
                    <td>480*480*31</td>
                    <td>charred_wood_burning</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF40.tar.gz">tvBTF40.tar.gz</a></td>
                    <td>235.0</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF41.gif"></td>
                    <td>220*220*30</td>
                    <td>waffle_toasting</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF41.tar.gz">tvBTF41.tar.gz</a></td>
                    <td>49.6</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF42.gif"></td>
                    <td>260*260*30</td>
                    <td>bread_toasting</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF42.tar.gz">tvBTF42.tar.gz</a></td>
                    <td>73.4</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF43.gif"></td>
                    <td>460*460*35</td>
                    <td>cast_iron_rusting_1</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF43.tar.gz">tvBTF43.tar.gz</a></td>
                    <td>227.2</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF44.gif"></td>
                    <td>460*460*34</td>
                    <td>copper_patina</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF44.tar.gz">tvBTF44.tar.gz</a></td>
                    <td>216.9</td>
                  </tr>
                  <tr>
                    <td><img src="https://cave.cs.columbia.edu/old/databases/staf/img/tvBTF45.gif"></td>
                    <td>460*460*30</td>
                    <td>cast_iron_rusting_2</td>
                    <td><a href="https://cave.cs.columbia.edu/old/databases/staf/tvBTF45.tar.gz">tvBTF45.tar.gz</a></td>
                    <td>200.3</td>
                  </tr>
                  <tr>
                    <td><b>Thumbnail</b></td>
                    <td><b>Resolution (width*height*time)</b></td>
                    <td><b>Description</b></td>
                    <td><b>Download</b></td>
                    <td><b>Size (MB)</b></td>
                  </tr>
                </tbody>
              </table>
              </td>
          </tr>
          <tr>
            <td><hr style="width: 100%; height: 2px;">
              <router-link to="/repository/STAF">STAF
Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:%20staf@lists.cs.columbia.edu">staf@lists.cs.columbia.edu</a><br>
Last modified: 08/28/2006 </td>
          </tr>
        </tbody></table>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>